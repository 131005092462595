.cover-entire {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1000;
  opacity: 0.5; 
}
.ant-spin-text{
  margin-top: 16px;
}