.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}


.segmented {
  background: #eff8ff;
  color: #3e79f7;
}

.segmented > .ant-segmented-group > .ant-segmented-item-selected {
  background: #3e79f7;
  color: #ffffff;
}

.segmented > .ant-segmented-group > .ant-segmented-thumb {
  background-color: #3e79f7;
}

.segmented > .ant-segmented-item:focus,
.ant-segmented-item:hover {
  color: #3e79f7;
}
.gantt-chart-stage{
margin: 0 auto !important;
width: 90px;
}

.ant-select-dropdown {
  z-index: 1;
}

.ant-popover {
  z-index: 2;
}
